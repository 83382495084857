import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoStoreComponent } from './no-store.component';
import { FontAwesomeImportModule } from '@app/_shared/material/font-awesome-import.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NoStoreComponent],
  exports: [NoStoreComponent, FontAwesomeImportModule, RouterModule],
  imports: [CommonModule, FontAwesomeImportModule, RouterModule],
})
export class NoStoreModule {}
