import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sb-no-store',
  templateUrl: './no-store.component.html',
})
export class NoStoreComponent {
  @Input() size: 'lg' | 'md' | 'sm';

  get stackSize() {
    switch (this.size) {
      case 'lg':
        return '5x';
      case 'md':
        return '4x';
      case 'sm':
        return '2x';
      default:
        return '1x';
    }
  }

  constructor(private _router: Router) {}

  createStore() {
    this._router.navigate(['/', 'stores', 'sales-platform']);
  }
}
